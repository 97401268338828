.u-over {
    overflow: visible;
}

.graph {
    flex-direction: column;
    align-items: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.u-legend {
    display: grid;
    grid-template-columns: repeat(3,1fr);
}

.u-inline {
    white-space: nowrap;
    width: 100%
}
.u-inline tr {
    margin-right: 0;
}